.container {
}

.map {
}

.tab {
    /* position: absolute; */
    right: 40%;
}
