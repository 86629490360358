@font-face {
  font-family: "Helvetica";
  src: url("./fonts/Helvetica.ttf") format("truetype");
}
@font-face {
  font-family: "Helvetica Neue";
  src: url("./fonts/HelveticaNeue.ttf") format("truetype");
}

body {
  margin: 0;
  background: #ffffff;
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue",
    Helvetica-Neue, HelveticaNeue, "Helvetica", "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans" Arial, Helvetica,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* padding: "100px"; */
}

.appMargin {
  padding: 1.5% 3%;
}

.map-tab {
  background-color: blueviolet;
  position: absolute;
  bottom: 40%;
  z-index: 999999;
}

/* .MuiGrid-container {
  margin: 10px;
} */

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans" Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media screen and (max-width: 425px) {
  body {
    font-size: 20px;
  }
}
